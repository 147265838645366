import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { handleBankLoginRedirect } from '@redux/actions/bank';
import MinimalPage from '@components/containers/MinimalPage';
import Section from '@components/containers/Section';
import Loader from '@components/elements/Loader';

const BankCallback = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      code,
      error,
      message,
      state,
      error_reason: errorReason,
      tracking_id: trackingId,
    } = queryString.parse(location.search);
    dispatch(handleBankLoginRedirect(state, code, message, error, errorReason, trackingId));
  }, [dispatch, location]);

  return (
    <MinimalPage>
      <Section>
        <Loader message="Hämtar information..." />
      </Section>
    </MinimalPage>
  );
};

export default BankCallback;
